
import { defineComponent, onMounted, ref, createVNode } from 'vue'
import { HomeOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { notification, Modal } from 'ant-design-vue'

import moment from 'moment'

import certficateService from '../../../services/certficate'
import courseService from '../../../services/course'
import commonServices from '../../../services/common'

export default defineComponent({
  components: {
    HomeOutlined
  },
  setup () {
    const profile = ref(commonServices.getCurrentProfile())
    const certficates = ref()
    const client = ref()
    const loader = ref<boolean>(false)
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const getCandidateCertficates = async (id) => {
      const responce = await certficateService.getCertficates(id)
      certficates.value = responce.data
      loader.value = false
    }
    const formatDate = (value) => {
      return moment(value).format('MM/DD/YYYY')
    }
    const downloadCertficate = async (course, certficate, profile, open) => {
      try {
        await certficateService.downloadCertficate(course, certficate, profile, open)
      } catch (error) {
        console.log('error', error)
        await notify('Error', error.data, 'error')
      }
    }
    const reAttemptCourse = async (index, course) => {
      try {
        const profile = commonServices.getCurrentProfile()
        await courseService.reTakeCourse(course._id, profile._id)
        certficates.value.splice(index, 1)
        notify('Success', 'Certficate revoked', 'success')
      } catch (error) {
        console.log('error', error)
        await notify('Error', error.data, 'error')
      }
    }
    const reTakeConfirm = async (index, course) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Are you sure want to re-take the course?.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk () {
          await reAttemptCourse(index, course)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    onMounted(async () => {
      const profile = commonServices.getCurrentProfile()
      getCandidateCertficates(profile._id)
    })
    return {
      certficates,
      formatDate,
      downloadCertficate,
      client,
      reTakeConfirm,
      loader,
      profile
    }
  }
})
