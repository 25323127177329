<template>
  <div class="main">
    <div class="candidate-certificate content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item> Certificate</a-breadcrumb-item>
      </a-breadcrumb>

      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <h4>Certificate</h4>
        </a-col>
      </a-row>
      <div class="body">
        <h4>
          Please note that once the course is completed, you can find the certificate been automatically added to your account.
        </h4>
        <a-row class="courses-list" :gutter="24">
          <a-col class="gutter-row course-details" :xs="24" :md="12" :xl="8" :xxl="6" v-for="(certficate, index) in certficates" :key="index">
            <div class="gutter-box">
              <div class="title">{{certficate.course.title}}</div>
              <div class="status">
                <p>
                  <span>
                    <img src="../../../assets/images/calendar.svg" />
                  </span>Completed on {{formatDate(certficate.date)}}
                </p>
                <p class="expiry">
                  <span>
                    <img src="../../../assets/images/alert-triangle.svg" />
                  </span>Expiring on {{formatDate(certficate.exp_date)}}
                </p>
              </div>
              <div class="btnSec">
                <a-button @click="reTakeConfirm(index, certficate.course)"> Take Course </a-button>
                <a-button type="primary" class="viewicon">
                  <a :href="certficate.certificate_url" target="_blank"><EyeOutlined /> View</a>
                </a-button>
                <a-button type="primary" class="downloadicon">
                  <a :href="certficate.certificate_url" target="_blank" download><DownloadOutlined /> Download</a>
                </a-button>
              </div>
            </div>
          </a-col>
          <a-col class="gutter-row course-details" :xs="24" :md="12" :xl="8" :xxl="6" v-if="loader">
            <div class="title">
              <h4>Please wait we are loading your certificates...</h4>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <img id='certficateLogo' src='./../../../assets/images/certifciate-head.png' style="display: none" />
    <img id='cpdLogo' src='./../../../assets/images/cpd_logo.png' style="display: none" />
    <img id='ceoSign' src='./../../../assets/images/ceo_sign.png' style="display: none" />
    <img id='trainerSign' src='./../../../assets/images/michael.png' style="display: none" />
    <img id='backpdf' src='./../../../assets/images/backpdf.png' style="display: none" />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, createVNode } from 'vue'
import { HomeOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { notification, Modal } from 'ant-design-vue'

import moment from 'moment'

import certficateService from '../../../services/certficate'
import courseService from '../../../services/course'
import commonServices from '../../../services/common'

export default defineComponent({
  components: {
    HomeOutlined
  },
  setup () {
    const profile = ref(commonServices.getCurrentProfile())
    const certficates = ref()
    const client = ref()
    const loader = ref<boolean>(false)
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const getCandidateCertficates = async (id) => {
      const responce = await certficateService.getCertficates(id)
      certficates.value = responce.data
      loader.value = false
    }
    const formatDate = (value) => {
      return moment(value).format('MM/DD/YYYY')
    }
    const downloadCertficate = async (course, certficate, profile, open) => {
      try {
        await certficateService.downloadCertficate(course, certficate, profile, open)
      } catch (error) {
        console.log('error', error)
        await notify('Error', error.data, 'error')
      }
    }
    const reAttemptCourse = async (index, course) => {
      try {
        const profile = commonServices.getCurrentProfile()
        await courseService.reTakeCourse(course._id, profile._id)
        certficates.value.splice(index, 1)
        notify('Success', 'Certficate revoked', 'success')
      } catch (error) {
        console.log('error', error)
        await notify('Error', error.data, 'error')
      }
    }
    const reTakeConfirm = async (index, course) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Are you sure want to re-take the course?.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk () {
          await reAttemptCourse(index, course)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    onMounted(async () => {
      const profile = commonServices.getCurrentProfile()
      getCandidateCertficates(profile._id)
    })
    return {
      certficates,
      formatDate,
      downloadCertficate,
      client,
      reTakeConfirm,
      loader,
      profile
    }
  }
})
</script>

<style lang="scss">
.candidate-certificate {
  .header {
    background: #fff;
    padding: 15px 20px;
    margin: 20px 0px 10px;
    border-radius: 8px;
    h4 {
      color: #646464;
      font-family: "TT Norms Pro Medium";
      font-size: 18px;
      text-align: left;
      margin: 0;
    }
  }
  .body {
    background: #ffffff;
    min-height: 200px;
    box-shadow: 0px 5px 10px #00000003;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    h4 {
      color: #646464;
      font-family: "TT Norms Pro Regular";
      font-size: 16px;
      text-align: left;
      margin: 0px 0 20px 0;
    }
    .courses-list {
      .course-details {
        margin: 0 0 20px 0;
        .gutter-box {
          background: #ffffff;
          height: 100%;
          box-shadow: 0px 5px 10px #0000000f;
          border: 1px solid #e5e5e5;
          border-radius: 8px;
          padding: 15px;
          cursor: pointer;
          .title {
            color: #7EAF1A;
            font-family: "TT Norms Pro Medium";
            text-align: left;
            font-size: 16px;
          }
          .status {
            text-align: left;
            margin: 20px 0px 25px;
            p {
              display: flex;
              align-items: center;
              font-family: "TT Norms Pro Regular";
              color: #707070;
              span {
                margin: 0 10px 0 0;
                width: 25px;
                display: block;
                img {
                  width: 22px;
                }
              }
              &.expiry {
                color: #f6596f;
              }
            }
          }
          .btnSec {
            display: flex;
            justify-content: space-between;
            .ant-btn {
              background-color: #7EAF1A;
              color: #fff;
              border-radius: 4px;
              font-family: "TT Norms Pro Medium";
              font-size: 12px;
              text-shadow: none;
              border: none;
              box-shadow: none;
              min-width: 90px;
              padding: 0 10px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1300px) {
  .candidate-certificate
    .body
    .courses-list
    .course-details
    .gutter-box
    .btnSec
    .ant-btn {
    min-width: fit-content;
  }
}
@media only screen and (max-width: 1200px) {
  .candidate-certificate
    .body
    .courses-list
    .course-details
    .gutter-box
    .btnSec
    .ant-btn {
    min-width: 90px;
  }
}
@media only screen and (max-width: 830px) {
  .candidate-certificate
    .body
    .courses-list
    .course-details
    .gutter-box
    .btnSec
    .ant-btn {
    min-width: fit-content;
  }
}
@media only screen and (max-width: 768px) {
  .candidate-certificate
    .body
    .courses-list
    .course-details
    .gutter-box
    .btnSec
    .ant-btn {
    min-width: 90px;
  }
}
@media only screen and (max-width: 500px) {
  .candidate-certificate
    .body
    .courses-list
    .course-details
    .gutter-box
    .btnSec {
    flex-direction: column;
    .ant-btn {
      margin: 0 0 5px 0;
    }
  }
}
</style>
